body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f4f4f4;
  color: #333;
}

header {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

nav a {
  margin: 0 1rem;
  text-decoration: none;
  color: #007bff;
}

footer {
  background-color: #fff;
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ddd;
}

.hero {
  text-align: center;
  padding: 2rem 1rem;
}

.products {
  display: flex;
  justify-content: space-around;
  padding: 2rem 1rem;
}

.product-card {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 45%;
}

.product-card h2 {
  margin-top: 0;
}

.product-card a {
  color: #007bff;
  text-decoration: none;
}
